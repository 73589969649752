<template>
    <div class="news-list">
        <div class="news-list-header">{{ t('events') }}</div>
        <div class="news-item" v-for="event in eventList" :key="event.id">
            <div class="date">{{ event.date }}</div>
            <a @click="openEvent(event)" class="title">{{ event.title }}</a>
        </div>
    </div>
</template>
<script>
const Axios = require('axios');

module.exports = {
    props: [ 'port_id', 'limit', 'openOnLoad' ],
    data: function() {
        return {
            eventList: [],
        };
    },
    methods: {
        openEvent: function(event, dontAddPath) {
            this.$emit('openEvent', event);
            if(window.ga) window.ga('send', 'pageview', Drupal.settings.basePath + Drupal.settings.pathPrefix + event.path);
            if(!dontAddPath) history.pushState({}, event.title, Drupal.absoluteUrl('/' + Drupal.settings.pathPrefix + event.path));
        },
        t: function(text) {
            return Drupal.settings.portmate_translations[text];
        }
    },
    mounted: function() {
        Axios.get(Drupal.absoluteUrl('/api/v1.0/events?filter[port]=' + this.port_id + '&range=' + this.limit)).then(response => {
            this.eventList = response.data.data;

            if(this.openOnLoad && this.openOnLoad.type == 'event') {
                const event = this.eventList.find(i => i.id === this.openOnLoad.id);
                if(event) this.openEvent(event, true);
            }
        }).catch(err => {
            console.error(err);
        });
    }
};
</script>