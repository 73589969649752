<template>
    <div class="popup">
        <h2>{{ port.name }}</h2>
        <a v-on:click="openPort">Open port!</a>
    </div>
</template>
<script>
module.exports = {
    props: [ 'map', 'port' ],
    methods: {
        openPort: function() {
            this.map.$root.viewPortDetails(this.port);
            // this.$root.selectedPortData = 
        }
    }
}
</script>