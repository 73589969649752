<template>
    <div id="featured-container">
        <div id="featured-inner">
            <h2>{{ title }}</h2>
                <!-- <span class="mdc-fab__icon material-icons">location_on</span> -->
                <!-- <span class="mdc-fab__label">{{ t('Show map') }}</span> -->
            <button class="mdc-button mdc-button--raised show-map-button" @click="showMap">
                <i class="material-icons mdc-button__icon" aria-hidden="true">location_on</i>
                <span class="mdc-button__label">{{ t('show_map') }}</span>
            </button>
            <div class="info-link">
                <a :href="info_page" v-if="info_page">{{ t('info') }}</a>
            </div>
            <div id="featured" class="hide-from-print">
                <div v-if="loading" class="loading">
                    <div class="loading-text">{{ t('Loading...') }}</div>
                    <div role="progressbar" class="mdc-linear-progress mdc-linear-progress--indeterminate">
                        <div class="mdc-linear-progress__buffering-dots"></div>
                        <div class="mdc-linear-progress__buffer"></div>
                        <div class="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
                            <span class="mdc-linear-progress__bar-inner"></span>
                        </div>
                        <div class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
                            <span class="mdc-linear-progress__bar-inner no-animation"></span>
                        </div>
                    </div>
                </div>
                <div id="toggle-promoted">
                    <a class="toggle-ports mdc-button mdc-button--outlined" @click="togglePromoted">{{ show_promoted ? t('nearby') : t('promoted') }}</a>
                </div>
                <div id="featured-ports" :class="{ visible: show_promoted }">
                    <port v-for="port in promoted" :key="port.id" :port="port" :active="port === activePort" :title="t('promoted')"></port>
                </div>
                <div id="nearby-ports" :class="{ visible: !show_promoted }">
                    <port v-for="port in nearby" :key="port.id" :port="port" :active="port === activePort" :title="t('nearby')"></port>
                </div>
                <!-- <port v-for="port in nearby" :key="port.id" :port="port" :click="setActive(port)"></port> -->
                <!-- <port v-for="port in promoted" :key="port.id" :port="port" :click="setActive(port)"></port> -->
                <div id="featured-pager">
                    <div v-if="show_promoted">
                        <a v-for="port in promoted" :key="port.id" class="pager-item" v-on:click="setActive(port)" v-bind:class="{ active: port === activePort }"></a>
                    </div>
                    <div v-if="!show_promoted">
                        <a v-for="port in nearby" :key="port.id" class="pager-item" v-on:click="setActive(port)" v-bind:class="{ active: port === activePort }"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
var Port = require('./Port.vue').default;

module.exports = {
    components: {
        Port
    },
    data: function() {
        return {
            promoted: [],
            nearby: [],
            ports: [],
            activePort: null,
            show_promoted: true,
            loading: true,
            title: Drupal.settings.front_title,
            info_page: Drupal.settings.portmate_info,
        }
    },
    mounted: function() {
        var _this = this;
        var ports = [];

        _this.$root.getPorts().then(function(pts) {
            ports = pts;

            _this.promoted = ports.filter(function(port) {
                return port.promoted == '1';
            });

            if(_this.promoted.length) {
                _this.setActive(_this.promoted[0]);
                _this.loading = false;
            }
            // _this.promoted.forEach(p => {
            //     _this.ports.push(p);
            // });

            // if(_this.ports.length) _this.setActive(_this.ports[0]);

            return _this.$root.getUserLocation();
        }).then(function(userCoords) {
            var nearest = [];
            var coords = { latitude: userCoords.latitude * 0.017453292519943, longitude: userCoords.longitude * 0.017453292519943 };
            var dist = [ ];

            ports.forEach(function(port) {
                if(!port.location || !port.location.lat || !port.location.lon) return true;

                var p = { port: port, distance: _this.distance(coords, { latitude: parseFloat(port.location.lat) * 0.017453292519943, longitude: parseFloat(port.location.lon) * 0.017453292519943 }) };

                nearest.push(p);
                // if(nearest.length < 1) {
                //     nearest.push(p);
                //     dist.push(p.distance);
                // } else if(nearest.length < 4) {
                //     if(p.distance < dist[0]) {
                //         dist.unshift(p.distance);
                //         nearest.unshift(p);
                //     } else {
                //         dist.push(p.distance);
                //         nearest.push(p);
                //     }
                // } else {
                //     if(p.distance < dist[0]) {
                //         dist.unshift(p.distance);
                //         dist.pop();

                //         nearest.unshift(p);
                //         nearest.pop();
                //     } else {
                //         dist[1] = p.distance;
                //         nearest[1] = p;
                //     }
                // }
            });

            nearest.sort((a, b) => a.distance - b.distance).slice(0, 4).forEach(function(port) {
                _this.nearby.push(port.port);
                // _this.ports.push(port.port);
            });

            console.log(nearest);

            if(!_this.show_promoted && _this.nearby.length) _this.setActive(_this.nearby[0]);
            _this.loading = false;
            // _this.ports = tempPorts;

            //     { id: 19, image: 'https://via.placeholder.com/200x200', name: 'Porin Satama', },
            //     { id: 110, image: 'https://via.placeholder.com/200x200', name: 'Ulvilan Satama', }
            // ]; }, 2000);
        }).catch(function(err) { console.error(err); });
    },
    methods: {
        distance: function(coord1, coord2) {
            var R = 6371; // km
            var x = (coord2.longitude - coord1.longitude) * Math.cos((coord1.latitude + coord2.latitude) / 2);
            var y = (coord2.latitude - coord1.latitude);
            var d = Math.sqrt(x * x + y * y) * R;
            return d;         
        },
        setActive: function(port) {
            this.activePort = port;
        },
        togglePromoted: function() {
            this.show_promoted = !this.show_promoted;

            if(this.show_promoted) {
                if(this.promoted.length) this.setActive(this.promoted[0]);
            } else {
                if(this.nearby.length) this.setActive(this.nearby[0]);                
            }
        },
        t: function(text) {
            return Drupal.settings.portmate_translations[text];
        },
        showMap() {
            this.$root.$el.classList.add('show-map');
            setTimeout(() =>{
                this.$root.map.invalidateSize();
            }, 400);
        }
    },
}
</script>