<template>
    <div id="port-search-filter">
        <button class="mdc-button" id="close-filter" @click="close">{{ t('close') }}</button>
        <div class="container">
            <div id="port-search-filter-inner">
                <h5>{{ t('depth') }}</h5>
                <div class="row">
                    <div class="col-lg-offset-4 col-lg-4 col-md-offset-3 col-md-6">
                        <div id="depth-container">
                            <div class="depth-num">0 m</div>
                            <div id="depth" class="mdc-slider mdc-slider--discrete" tabindex="0" role="slider" data-step="1" aria-valuemin="0" aria-valuemax="5" aria-valuenow="0" aria-label="">
                                <div class="mdc-slider__track-container">
                                    <div class="mdc-slider__track"></div>
                                </div>
                                <div class="mdc-slider__thumb-container">
                                    <div class="mdc-slider__pin">
                                    <span class="mdc-slider__pin-value-marker"></span>
                                    </div>
                                    <svg class="mdc-slider__thumb" width="21" height="21">
                                    <circle cx="10.5" cy="10.5" r="7.875"></circle>
                                    </svg>
                                    <div class="mdc-slider__focus-ring"></div>
                                </div>
                            </div>
                            <div class="depth-num">5 m</div>
                        </div>
                    </div>
                </div>
                <h5>{{ t('mooring') }}</h5>
                
                <div class="row mooring-switches">
                    <div class="mooring-col" v-for="(mooring, index) in moorings" :key="index">
                        <label :for="'mooring-switch-' + mooring.id">{{ mooring.title }}</label>
                        <div class="mdc-switch" data-type="mooring" :data-value="mooring.id">
                            <div class="mdc-switch__track"></div>
                            <div class="mdc-switch__thumb-underlay">
                                <div class="mdc-switch__thumb">
                                    <input type="checkbox" :value="mooring.id" :id="'mooring-switch-' + mooring.id" class="mdc-switch__native-control" role="switch">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h5>{{ t('services') }}</h5>
                
                <div class="row services-switches">
                    <div class="services-col" v-for="(service, index) in services" :key="index">
                        <label :for="'services-switch-' + service.id">{{ service.title }}</label>
                        <div class="mdc-switch" data-type="service" :data-value="service.id">
                            <div class="mdc-switch__track"></div>
                            <div class="mdc-switch__thumb-underlay">
                                <div class="mdc-switch__thumb">
                                    <input type="checkbox" :value="service.id" :id="'services-switch-' + service.id" class="mdc-switch__native-control" role="switch">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="buttons">
                    <div class="col-sm-7 col-sm-offset-5 text-left">
                        <button class="mdc-button mdc-button--raised save" @click="save">{{ t('save_and_continue') }}</button>
                        <button class="mdc-button clear" @click="clear">{{ t('clear') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const { MDCSlider } = require('@material/slider');
const { MDCSwitch } = require('@material/switch');

module.exports = {
    data: function() {
        return {
            data: this.$root.filters,
            switches: { moorings: [], services: [] },
            slider: null,
            moorings: Drupal.settings.portmate_filters.moorings,
            services: Drupal.settings.portmate_filters.port_services,
        }
    },
    mounted: function() {
        this.slider = new MDCSlider(document.getElementById('depth'));
        if(this.data.depth) this.slider.value = this.data.depth;

        document.querySelectorAll('.mdc-switch').forEach(i => {
            if(i.attributes['data-type'].value == 'mooring') {
                const swtch = new MDCSwitch(i);
                swtch.checked = this.data.moorings.indexOf(parseInt(i.attributes['data-value'].value)) >= 0;

                this.switches.moorings.push(swtch);
            } else if(i.attributes['data-type'].value == 'service') {
                const swtch = new MDCSwitch(i);
                swtch.checked = this.data.services.indexOf(parseInt(i.attributes['data-value'].value)) >= 0;

                this.switches.services.push(swtch);
            }
        });
    },
    methods: {
        close: function() {
            this.$emit('onFilterClose');
        },
        t: function(text) {
            return Drupal.settings.portmate_translations[text];
        },
        save: function() {
            const data = {
                depth: this.slider.value,
                moorings: [],
                services: [],
            };

            this.switches.moorings.forEach(i => {
                if(i.checked) data.moorings.push(parseInt(i.root_.attributes['data-value'].value));
            });

            this.switches.services.forEach(i => {
                if(i.checked) data.services.push(parseInt(i.root_.attributes['data-value'].value));
            });

            this.close();            
            this.$emit('onFilterChanged', data);
        },
        clear: function() {
            this.switches.moorings.forEach(i => i.foundation_.setChecked(false));
            this.switches.services.forEach(i => i.foundation_.setChecked(false));
            this.slider.value = null;

            const data = {
                depth: null,
                moorings: [],
                services: [],
            };

            this.$emit('onFilterChanged', data);
        }
    }
}
</script>
