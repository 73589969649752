<template>
    <div id="popup-info" :class="{ visible: visible }">
        <div class="background"></div>
        <a class="close-button material-icons" @click="close">add_circle</a>
        <div class="popup-inner" v-if="data">
            <a :href="data.left_link" class="safety normal">{{ data.left_description }}</a>
            <a :href="data.left_link" class="safety big btn">{{ data.left_link_title }}</a>
            <a :href="data.right_link" class="resource big btn">{{ data.right_link_title }}</a>
            <a :href="data.right_link" class="resource normal">{{ data.right_description }}</a>
        </div>
    </div>
</template>
<script>
const moment = require('moment');

module.exports = {
    data: function() {
        return {
            visible: false,
            data: null,
        }
    },
    mounted: function() {
        this.data = Drupal.settings.portmate_popup;
        if(!this.data) return;

        // this.visible = true;
        // return 1==1; // DEMO
        
        const ps = window.localStorage.getItem('popup_show_expires');
        const date = moment().format('YYYY-MM-DD');

        if(!ps || ps !== date) {
            this.visible = true;
            window.localStorage.setItem('popup_show_expires', date);
        }
    },
    methods: {
        close: function(e) {
            e.stopPropagation();
            this.visible = false;
        }
    }
};
</script>