<template>
    <div class="news-list">
        <div class="news-list-header">{{ t('news') }}</div>
        <div class="news-item" v-for="news in newsList" :key="news.id">
            <div class="date">{{ news.date }}</div>
            <a @click="openNews(news)" class="title">{{ news.title }}</a>
        </div>
    </div>
</template>
<script>
const Axios = require('axios');

module.exports = {
    props: [ 'port_id', 'limit', 'openOnLoad' ],
    data: function() {
        return {
            newsList: [],
        };
    },
    methods: {
        openNews: function(news, dontAddPath) {
            this.$emit('openNews', news);
            if(window.ga) window.ga('send', 'pageview', Drupal.settings.basePath + Drupal.settings.pathPrefix + news.path);
            if(!dontAddPath) history.pushState({}, news.title, Drupal.absoluteUrl('/' + Drupal.settings.pathPrefix + news.path));
        },
        t: function(text) {
            return Drupal.settings.portmate_translations[text];
        }
    },
    mounted: function() {
        Axios.get(Drupal.absoluteUrl('/api/v1.0/news?filter[port]=' + this.port_id + '&range=' + this.limit)).then(response => {
            this.newsList = response.data.data;

            if(this.openOnLoad && this.openOnLoad.type == 'news') {
                const news = this.newsList.find(i => i.id === this.openOnLoad.id);
                if(news) this.openNews(news, true);
            }
        }).catch(err => {
            console.error(err);
        });
    }
};
</script>