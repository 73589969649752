<template>
    <div id="info-messages" :class="{ visible: visible }">
        <div id="info-messages-list">
            <div class="info-message" v-for="(message, index) in messages" :key="index" :class="message.severity">
                <div v-html="message.content"></div>
            </div>
        </div>
        <button class="mdc-button mdc-button--raised" @click="close">{{ t('close') }}</button>
    </div>
</template>
<script>
module.exports = {
    data: function() {
        return {
            visible: false,
            messages: [],
        }
    },
    mounted: function() {
        const msgs = Drupal.settings.info_messages;
        if(!msgs || msgs.length === 0) return;

        for(let msgType in msgs) {
            for(let msg in msgs[msgType]) {
                this.messages.push({
                    severity: msgType,
                    content: msgs[msgType][msg]
                });
            }
        }

        this.visible = true;

        setTimeout(() => {
            this.visible = false;
        }, 15000);
    },
    methods: {
        close: function(e) {
            e.stopPropagation();
            this.visible = false;
        },
        t: function(txt) {
            return Drupal.settings.portmate_translations[txt];
        }
    }
};
</script>