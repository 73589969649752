<template>
    <div id="port-details-container">
        <div id="port-details" class="like-body">
            <div id="port-details-inner" class="container">
                <div class="clearfix"></div>
                <div v-if="!data" class="loading">
                    <div class="loading-text">{{ t('loading...') }}</div>
                    <div role="progressbar" class="mdc-linear-progress mdc-linear-progress--indeterminate">
                        <div class="mdc-linear-progress__buffering-dots"></div>
                        <div class="mdc-linear-progress__buffer"></div>
                        <div class="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
                            <span class="mdc-linear-progress__bar-inner"></span>
                        </div>
                        <div class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
                            <span class="mdc-linear-progress__bar-inner no-animation"></span>
                        </div>
                    </div>
                </div>
                <div v-if="data">
                    <div class="port-header row">
                        <a class="hide-from-print col-lg-2 port-links" v-if="!activeNews && !activeEvent" v-on:click="print">{{ t('print_portcard') }}</a>
                        <a class="hide-from-print col-lg-2 port-links" v-if="activeNews || activeEvent" v-on:click="closeNewsAndEvent">{{ t('back') }}</a>
                        <h2 class="col-lg-8">
                            {{ data.port_name }}
                            <img class="country-flag" v-if="data.country_code" :src="country_flag_path + data.country_code + '.png'" />
                        </h2>
                        <div class="hide-from-print col-lg-2 text-right port-links" v-if="!activeNews && !activeEvent">
                            <span v-if="port_edit_link">
                                <a :href="port_edit_link">{{ t('edit') }}</a>
                                <br />
                            </span>
                            <a @click="close">{{ t('close') }}</a>
                        </div>
                    </div>
                    <div v-if="activeNews">
                        <div class="row">
                            <div class="col-md-6">
                                <h2>{{ activeNews.title }}</h2>
                                <div class="news-images-wrapper" v-if="activeNews.images.length > 0">
                                    <div class="news-images" v-for="image in activeNews.images" :key="image">
                                        <img :src="image" />
                                    </div>
                                </div>
                                <div class="" v-html="activeNews.description"></div>
                            </div>
                            <div class="col-md-6">
                                <news-list @openNews="openNews" :port_id="data.id" :limit="5"></news-list>
                            </div>
                        </div>
                    </div>
                    <div v-if="activeEvent">
                        <div class="row">
                            <div class="col-md-6">
                                <h2>{{ activeEvent.title }}</h2>
                                <div class="event-images-wrapper" v-if="activeEvent.images.length > 0">
                                    <div class="event-images" v-for="image in activeEvent.images" :key="image">
                                        <img :src="image" />
                                    </div>
                                </div>
                                <div class="" v-html="activeEvent.description"></div>
                            </div>
                            <div class="col-md-6">
                                <event-list @openEvent="openEvent" :port_id="data.id" :limit="5"></event-list>
                            </div>
                        </div>
                    </div>
                    <div v-if="!activeNews && !activeEvent">
                        <div class="port-contact-info row text-center">
                            <div class="col-lg-2">
                                <div v-if="data.phone_number_1">
                                    <a :href="'tel:' + data.phone_number_1">{{ data.phone_number_1 }}</a>
                                </div>
                                <div v-if="data.phone_number_2">
                                    <a :href="'tel:' + data.phone_number_2">{{ data.phone_number_2 }}</a>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <a v-if="data.phone_number_whatsapp" :href="'tel:' + data.phone_number_whatsapp">WA {{ data.phone_number_whatsapp }}</a>
                            </div>
                            <div class="col-lg-4">
                                <a class="link-block" :href="'mailto:' + data.email" v-if="data.email">{{ data.email }}</a>
                                <a class="link-block" :href="data.website" v-if="data.website">{{ data.website }}</a>
                            </div>
                            <div class="col-lg-4 text-right hide-from-print">
                                <a class="some-icon fb" :href="data.facebook" v-if="data.facebook"></a>
                                <a class="some-icon yt" :href="data.youtube" v-if="data.youtube"></a>
                                <a class="some-icon ig" :href="data.instagram" v-if="data.instagram"></a>
                                <a class="some-icon tw" :href="data.twitter" v-if="data.twitter"></a>
                                <!-- <a class="hide-from-print" v-on:click="flag" v-bind:href="data.favorite.link" v-bind:class="{ flag: flag_action == 'flag', processing: flag_processing }">{{ flag_action == 'flag' ? 'Flag it!' : 'Unflag this' }}</a>, -->
                            </div>
                        </div>

                        <div class="port-fastening-info row">
                            <div class="col-md-7">
                                <span v-if="data.port_number">
                                    {{ t('port_number') }}: 
                                    <strong>{{ data.port_number }}</strong>
                                </span>
                                
                                <span v-if="data.marine_area">
                                    {{ t('map_area') }}: 
                                    <strong>{{ data.marine_area }}</strong>
                                </span>
                                
                                <span v-if="data.gps">
                                    {{ t('location') }}: 
                                    <strong>{{ data.gps.lat }}, {{ data.gps.lon }}</strong>
                                </span>
                            </div>

                            <div class="col-md-5 text-right booking-address">
                                <!-- <div class="mooring-icon" v-for="(mooring, index) in data.mooring_count" :key="index">
                                    <i class="material-icons">all_out</i>
                                    <span class="mi-text">{{ mooring.count }}</span>
                                </div> -->
                                <a v-if="data.online_booking_address" :href="data.online_booking_address">{{ t('book') }}</a>
                            </div>
                        </div>
                    
                        <div class="tab-container row port-media">
                            <div v-show="activeTab == 'images'" class="images hide-from-print" v-if="data.which_tab_first == 'images' && imageCount > 0">
                                <div class="wrapper">
                                    <div v-bind:class="{ prev: index < currentImage, current: index == currentImage, next: index > currentImage }" v-bind:key="image" class="image" v-for="(image, index) in data.images">
                                        <img v-bind:src="image" />
                                    </div>
                                    <a @click="prevImage" class="arrow arrow-left" v-if="imageCount > 0 && currentImage > 0"></a>
                                    <a @click="nextImage" class="arrow arrow-right" v-if="imageCount > 0 && currentImage < (imageCount - 1)"></a>
                                </div>
                            </div>
                            <div v-show="activeTab == 'videos'" class="videos hide-from-print" v-if="videoCount > 0">
                                <div class="wrapper">
                                    <div v-bind:class="{ prev: index < currentVideo, current: index == currentVideo, next: index > currentVideo }" v-bind:key="video" class="video" v-for="(video, index) in videos">
                                        <iframe :src="video" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                    <a @click="prevVideo" class="arrow arrow-left" v-if="videoCount > 0 && currentVideo > 0"></a>
                                    <a @click="nextVideo" class="arrow arrow-right" v-if="videoCount > 0 && currentVideo < (videoCount - 1)"></a>
                                </div>
                            </div>
                            <div v-show="activeTab == 'images'" class="images" v-if="data.which_tab_first != 'images' && imageCount > 0">
                                <div class="wrapper">
                                    <div v-bind:class="{ prev: index < currentImage, current: index == currentImage, next: index > currentImage }" v-bind:key="image" class="image" v-for="(image, index) in data.images">
                                        <img v-bind:src="image" />
                                    </div>
                                    <a @click="prevImage" class="arrow arrow-left" v-if="imageCount > 0 && currentImage > 0"></a>
                                    <a @click="nextImage" class="arrow arrow-right" v-if="imageCount > 0 && currentImage < (imageCount - 1)"></a>
                                </div>
                            </div>
                            <div v-show="activeTab == 'map'" v-if="hasMap" id="port-map-wrapper">
                                <div id="port-map"></div>
                                <div class="not-for-navigation">{{ t('map_navigation_disclaimer') }}</div>
                            </div>
                            <div v-show="activeTab == 'windy'" v-if="hasMap" id="windy-map">
                                <iframe width="100%" height="100%" :src="'https://embed.windy.com/embed2.html?lat=' + data.gps.lat + '&lon=' + data.gps.lon + '&zoom=9&level=surface&overlay=wind&menu=&message=&marker=true&calendar=&pressure=&type=map&location=coordinates&detail=&detailLat=lat=' + data.gps.lat + '&detailLon=' + data.gps.lon + '&metricWind=m%2Fs&metricTemp=%C2%B0C&radarRange=-1'" frameborder="0"></iframe>
                            </div>
                        </div>
                        <div class="tabs row hide-from-print">
                            <a v-if="data.which_tab_first == 'images' && imageCount > 0" v-bind:class="{ active: activeTab == 'images' }" v-on:click="setActiveTab('images')">{{ t('images') }}</a>
                            <a v-if="videoCount > 0" v-bind:class="{ active: activeTab == 'videos' }" v-on:click="setActiveTab('videos')">{{ t('videos') }}</a>
                            <a v-if="data.which_tab_first != 'images' && imageCount > 0" v-bind:class="{ active: activeTab == 'images' }" v-on:click="setActiveTab('images')">{{ t('images') }}</a>
                            <a v-if="hasMap" v-bind:class="{ active: activeTab == 'map' }" v-on:click="setActiveTab('map')">{{ t('local_map') }}</a>
                            <a v-if="hasMap" v-bind:class="{ active: activeTab == 'windy' }" v-on:click="setActiveTab('windy')">{{ t('weather') }}</a>
                        </div>
                        <p class="port-description" v-if="data.port_description" v-html="data.port_description"></p>

                        <div class="row">
                            <!-- <toggleable-content :key="index" v-for="(tc, index) in toggleableContent" :title="tc.title" :content="tc.content"></toggleable-content> -->
                            <div class="toggleable-content">
                                <a class="title" @click="toggleContent">
                                    <span class="toggle-icon"></span>
                                    <span class="text">{{ t('arrival_info') }}</span>
                                </a>
                                <div class="content">
                                    <div class="p" v-if="data.arrival_info" v-html="data.arrival_info"></div>
                                    <div class="p" v-if="data.street_address">
                                        <h5>{{ t('address') }}</h5>
                                        {{ data.street_address }} {{ data.street_number }}<br />{{ data.postal_code }} {{ data.town }}
                                    </div>
                                    <div class="p" v-if="data.mooring.length">
                                        <h5>{{ t('mooring') }}</h5>
                                        <table class="table auto-width">
                                            <thead>
                                                <tr>
                                                    <th>{{ t('type') }}</th>
                                                    <th>{{ t('amount') }}</th>
                                                    <th>{{ t('depth') }}</th>
                                                    <th>{{ t('width') }}</th>
                                                    <th>{{ t('method') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(mooring, index) in data.mooring" :key="index">
                                                    <td>{{ mooring.type }}</td>
                                                    <td>{{ mooring.amount }}</td>
                                                    <td>{{ mooring.depth }}{{ mooring.depth ? ' m' : '' }}</td>
                                                    <td>{{ mooring.width }}{{ mooring.width ? ' m' : '' }}</td>
                                                    <td>{{ mooring.method }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="p" v-if="data.interfering_wind.length">
                                        <h5>{{ t('interfering_wind') }}</h5>
                                        <table class="table auto-width">
                                            <thead>
                                                <tr>
                                                    <th>{{ t('direction') }}</th>
                                                    <th>{{ t('intensity') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(interfering_wind, index) in data.interfering_wind" :key="index">
                                                    <td>{{ interfering_wind.direction }}</td>
                                                    <td>{{ interfering_wind.intensity }} m/s</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="p" v-if="data.bridges.length">
                                        <h5>{{ t('bridges') }}</h5>
                                        <table class="table auto-width">
                                            <thead>
                                                <tr>
                                                    <th>{{ t('height') }}</th>
                                                    <th>{{ t('details') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(bridge, index) in data.bridges" :key="index">
                                                    <td>{{ bridge.height }} m</td>
                                                    <td>{{ bridge.details }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="p" v-if="data.map_images.length">
                                        <h5>{{ t('map_images') }}</h5>
                                        <p v-for="(image, index) in data.map_images" :key="index">
                                            <a :href="image" target="_blank">
                                                <img :src="image" class="img-responsive" />
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="toggleable-content">
                                <a class="title" @click="toggleContent">
                                    <span class="toggle-icon"></span>
                                    <span class="text">{{ t('info') }}</span>
                                </a>
                                <div class="content">
                                    <div class="p" v-html="data.other_information"></div>
                                </div>
                            </div>

                            <div class="toggleable-content">
                                <a class="title" @click="toggleContent">
                                    <span class="toggle-icon"></span>
                                    <span class="text">{{ t('what_to_do_or_rent') }}</span>
                                </a>
                                <div class="content">
                                    <div class="p" v-html="data.activities_for_rent"></div>
                                </div>
                            </div>

                            <div class="toggleable-content">
                                <a class="title" @click="toggleContent">
                                    <span class="toggle-icon"></span>
                                    <span class="text">{{ t('local_traffic') }}</span>
                                </a>
                                <div class="content">
                                    <div class="p" v-html="data.local_transport"></div>
                                    <p v-if="data.local_transport_link">
                                        <a :href="data.local_transport_link">{{ t('local_transport_website') }}</a>
                                    </p>
                                </div>
                            </div>

                            <div class="toggleable-content">
                                <a class="title" @click="toggleContent">
                                    <span class="toggle-icon"></span>
                                    <span class="text">{{ t('local_historic') }}</span>
                                </a>
                                <div class="content">
                                    <div class="p" v-html="data.local_historic"></div>
                                </div>
                            </div>

                            <div class="toggleable-content" v-if="data.eu_investments.length > 0">
                                <a class="title" @click="toggleContent">
                                    <span class="toggle-icon"></span>
                                    <span class="text">{{ t('eu_investments') }}</span>
                                </a>
                                <div class="content">
                                    <ul>
                                        <li v-for="(investment, index) in data.eu_investments" :key="index">{{ investment }}</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="toggleable-content" v-if="data.resource_efficiency">
                                <a class="title" @click="toggleContent">
                                    <span class="toggle-icon"></span>
                                    <span class="text">{{ t('resource_efficiency') }}</span>
                                </a>
                                <div class="content">
                                    <div v-if="data.resource_efficiency.energy">
                                        <h4>{{ t('group_energy_supply_ee') }}</h4>
                                        <h5 v-if="data.resource_efficiency.energy.is_the_energy_supply_from_ || data.resource_efficiency.energy.is_there_self_production_o">{{ t('group_energy_supply') }}</h5>
                                        <p v-if="data.resource_efficiency.energy.is_the_energy_supply_from_">
                                            <strong>{{ data.resource_efficiency.energy.is_the_energy_supply_from_.label }}</strong><br />
                                            {{ data.resource_efficiency.energy.is_the_energy_supply_from_.value }}
                                        </p>
                                        <p v-if="data.resource_efficiency.energy.is_there_self_production_o">
                                            <strong>{{ data.resource_efficiency.energy.is_there_self_production_o.label }}</strong><br />
                                            {{ data.resource_efficiency.energy.is_there_self_production_o.value }}
                                        </p>
                                        <h5 v-if="data.resource_efficiency.energy.is_the_energy_consumption_ || data.resource_efficiency.energy.has_the_energy_efficiency_ || data.resource_efficiency.energy.is_the_port_using_technolo || data.resource_efficiency.energy.has_some_other_actions_bee || data.resource_efficiency.energy.what_actions_">{{ t('group_energy_efficiency') }}</h5>
                                        <p v-if="data.resource_efficiency.energy.is_the_energy_consumption_">
                                            <strong>{{ data.resource_efficiency.energy.is_the_energy_consumption_.label }}</strong><br />
                                            {{ data.resource_efficiency.energy.is_the_energy_consumption_.value }}
                                        </p>
                                        <p v-if="data.resource_efficiency.energy.has_the_energy_efficiency_">
                                            <strong>{{ data.resource_efficiency.energy.has_the_energy_efficiency_.label }}</strong><br />
                                            {{ data.resource_efficiency.energy.has_the_energy_efficiency_.value }}
                                        </p>
                                        <p v-if="data.resource_efficiency.energy.is_the_port_using_technolo">
                                            <strong>{{ data.resource_efficiency.energy.is_the_port_using_technolo.label }}</strong><br />
                                            {{ data.resource_efficiency.energy.is_the_port_using_technolo.value }}
                                        </p>
                                        <p v-if="data.resource_efficiency.energy.has_some_other_actions_bee">
                                            <strong>{{ data.resource_efficiency.energy.has_some_other_actions_bee.label }}</strong><br />
                                            {{ data.resource_efficiency.energy.has_some_other_actions_bee.value }}
                                        </p>
                                        <p v-if="data.resource_efficiency.energy.what_actions_">
                                            <strong>{{ data.resource_efficiency.energy.what_actions_.label }}</strong><br />
                                            <span class="p">{{ data.resource_efficiency.energy.what_actions_.value }}</span>
                                        </p>
                                    </div>

                                    <div v-if="data.resource_efficiency.water">
                                        <h4>{{ t('group_drinking_water_and_was') }}</h4>
                                        <p v-if="data.resource_efficiency.water.what_types_of_toilets_are_">
                                            <strong>{{ data.resource_efficiency.water.what_types_of_toilets_are_.label }}</strong><br />
                                            {{ data.resource_efficiency.water.what_types_of_toilets_are_.value }}
                                        </p>
                                        <p v-if="data.resource_efficiency.water.how_the_drinking_water_sup">
                                            <strong>{{ data.resource_efficiency.water.how_the_drinking_water_sup.label }}</strong><br />
                                            {{ data.resource_efficiency.water.how_the_drinking_water_sup.value }}
                                        </p>
                                        <p v-if="data.resource_efficiency.water.how_the_wastewater_is_hand">
                                            <strong>{{ data.resource_efficiency.water.how_the_wastewater_is_hand.label }}</strong><br />
                                            {{ data.resource_efficiency.water.how_the_wastewater_is_hand.value }}
                                        </p>
                                        <p v-if="data.resource_efficiency.water.drinking_water_consumption">
                                            <strong>{{ data.resource_efficiency.water.drinking_water_consumption.label }}</strong><br />
                                            {{ data.resource_efficiency.water.drinking_water_consumption.value }}
                                        </p>
                                    </div>

                                    <div v-if="data.resource_efficiency.waste">
                                        <h4>{{ t('group_waste_management') }}</h4>
                                        <p v-if="data.resource_efficiency.waste.what_waste_fractions_are_c">
                                            <strong>{{ data.resource_efficiency.waste.what_waste_fractions_are_c.label }}</strong><br />
                                            {{ data.resource_efficiency.waste.what_waste_fractions_are_c.value }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h3>{{ t('services') }}</h3>
                        <div class="services clearfix">
                            <div class="row">
                                <div class="service col-lg-2 col-md-3 col-sm-4" v-for="(service, index) in services" :key="index">
                                    <div class="name text-with-icon">
                                        <i v-if="!service.service_icon" class="material-icons">business</i>
                                        <img class="service-icon" v-if="service.service_icon" :src="service.service_icon" alt="" />
                                        <span class="text">{{ service.service }}</span>
                                    </div>
                                    <div class="available" v-if="service.available.from">
                                        <span class="from">{{ service.available.from }}</span>
                                        <span class="to" v-if="service.available.to"> - {{ service.available.to }}</span>
                                    </div>
                                    <div class="info" v-if="service.info">{{ service.info }}</div>
                                </div>
                            </div>
                            <!-- <div class="visible-sm clearfix" v-if="index % 3 == 2"></div>
                            <div class="visible-md clearfix" v-if="index % 4 == 3"></div>
                            <div class="visible-lg clearfix" v-if="index % 6 == 5"></div> -->
                        </div>

                        <div class="links hide-from-print">
                            <div class="left">
                            </div>
                            <div class="center">
                                <button class="mdc-button" @click="showAllServices" v-if="!showingAllServices && hasMoreServices">{{ t('show_all') }}</button>
                            </div>
                            <div class="right">
                                <a :href="feedback_url">{{ t('missing_service_tell_us') }}</a>
                            </div>
                        </div>

                        <div v-if="data.services_info">
                            <h5>{{ t('services_info') }}</h5>
                            <div class="content">
                                <div class="p" v-html="data.services_info"></div>
                            </div>
                        </div>

                        <div v-if="data.port_nearby_services">
                            <h5>{{ t('port_nearby_services') }}</h5>
                            <div class="content">
                                <div class="p" v-html="data.port_nearby_services"></div>
                            </div>
                        </div>

                        <!--
                        <h3>{{ t('Visits') }}<small>42 visits</small></h3>
                        <div class="visits clearfix">
                            <div class="row">
                                <div class="visit col-lg-4 col-md-6" v-for="(visit, index) in visits" :key="index">
                                    <div class="row">
                                        <div class="name col-xs-6">
                                            {{ visit.name }}
                                        </div>
                                        <div class="rating col-xs-6">
                                            <div :id="'rating-' + index">
                                                <a @click="rateFeature($event, index, visit, 20)" class="rating material-icons" :class="{ filled: visit.value > 0 }">whatshot</a>
                                                <a @click="rateFeature($event, index, visit, 40)" class="rating material-icons" :class="{ filled: visit.value > 20 }">whatshot</a>
                                                <a @click="rateFeature($event, index, visit, 60)" class="rating material-icons" :class="{ filled: visit.value > 40 }">whatshot</a>
                                                <a @click="rateFeature($event, index, visit, 80)" class="rating material-icons" :class="{ filled: visit.value > 60 }">whatshot</a>
                                                <a @click="rateFeature($event, index, visit, 100)" class="rating material-icons" :class="{ filled: visit.value > 80 }">whatshot</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!- <div class="visible-sm clearfix" v-if="index % 3 == 2"></div>
                            <div class="visible-md clearfix" v-if="index % 4 == 3"></div>
                            <div class="visible-lg clearfix" v-if="index % 6 == 5"></div> ->
                        </div>

                        <div class="links hide-from-print">
                            <div class="left">
                                <a :href="login_url">{{ t('Have you visit this port? Log in and leave your own estimate') }}</a>
                            </div>
                            <div class="right">
                                <a :href="feedback_url">{{ t('Send feedback') }}</a>
                            </div>
                        </div>
                        -->

                        <!-- <div class="row"> -->
                            <!-- <div class="col-" -->

                        <div class="row">
                            <div class="col-md-6">
                                <news-list @openNews="openNews" :openOnLoad="openAtStartup" :port_id="data.id" :limit="2"></news-list>
                            </div>
                            <div class="col-md-6">
                                <event-list @openEvent="openEvent" :openOnLoad="openAtStartup" :port_id="data.id" :limit="2"></event-list>
                            </div>
                        </div>
                    </div>

                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const Axios = require('axios');
const ToggleableContent = require('./ToggleableContent.vue').default;
const NewsList = require('./NewsList.vue').default;
const EventList = require('./EventList.vue').default;

module.exports = {
    components: { ToggleableContent, NewsList, EventList },
    // props: [ 'data' ],
    data: () => {
        return {
            activeTab: 'images',
            data: null,
            flag_action: null,
            flag_processing: false,
            map: null,
            hasMap: false,
            imageCount: 0,
            currentImage: 0,
            videoCount: 0,
            currentVideo: 0,
            videos: [],
            showingAllServices: false,
            hasMoreServices: false,
            services: [],
            activeNews: null,
            activeEvent: null,
            toggleableContent: [],
            visits: Drupal.settings.portmate_visits,
            openAtStartup: null,
            feedback_url: null,
            port_edit_link: null,
            login_url: Drupal.settings.login_current_page,
            country_flag_path: Drupal.settings.basePath + 'sites/all/themes/portmate_bs/images/countries/',
        };
    },
    mounted: function() {
        this.$root.selectedPortDataChanged = this.dataChanged;

        if(this.$root.selectedPortData) {
            this.dataChanged(this.$root.selectedPortExtraData);
        }
            
        // window.matchMedia('print').addListener((mql) => {
        //     if (mql.matches) {
        //         this.setActiveTab('map');
        //     }
        // });
        // document.getElementById('port-details');
        setTimeout(() => {
            document.addEventListener("click", this.onClickEvent);
        }, 200);
    },
    methods: {
        closeNewsAndEvent: function() {
            this.activeNews = null;
            this.activeEvent = null;
            this.openAtStartup = null;
            history.pushState({}, this.data.port_name, Drupal.absoluteUrl('/' + Drupal.settings.pathPrefix + this.data.path));
        },
        openNews: function(data) {
            this.activeNews = data;
        },
        openEvent: function(data) {
            this.activeEvent = data;
        },
        dataChanged: function(extra) {
            this.services.splice(0, this.services.length);
            this.$set(this, 'data', this.$root.selectedPortData);

            if(this.data !== null) {
                this.feedback_url = Drupal.settings.port_feedback_form + '?port_id=' + this.data.id,
                this.flag_action = this.data.favorite.action;
                this.imageCount = this.data.images.length;
                this.currentImage = 0;
                this.hasMap = this.data.gps ? true : false;
                this.port_edit_link = this.data.port_edit_link;

                this.videos = [];
                for(const v of this.data.youtube_videos) this.videos.push(v);
                for(const v2 of this.data.vimeo_videos) this.videos.push(v2);

                this.videoCount = this.videos.length;
                this.currentVideo = 0;

                if(this.data.which_tab_first) {
                    if(this.data.which_tab_first == 'images' && this.imageCount) this.setActiveTab('images');
                    else if(this.data.which_tab_first == 'videos' && this.videoCount) this.setActiveTab('videos');
                    else if(this.data.which_tab_first == 'videos' && this.imageCount) this.setActiveTab('images');
                    else this.setActiveTab('map');
                } else {
                    if(this.videoCount) this.setActiveTab('videos');
                    else if(this.imageCount) this.setActiveTab('images');
                    else this.setActiveTab('map');
                }

                if(this.map !== null) {
                    this.destroyMap();
                }

                // this.getFiveStarForm(0, this.data.id, 'field_feature_1_rating');
                this.setRatingData();

                if(extra) this.openAtStartup = extra;

                this.data.port_services_1.forEach(i => this.services.push(i));
                this.hasMoreServices = this.data.port_services_2.length;

                this.$set(this, 'toggleableContent', [
                    { title: this.t('Arrival info'), content: [ '<p>Lorem ipsum.</p>' ] },
                    { title: this.t('Info'), content: [ this.data.other_information ] },
                    { title: this.t('What to do or rent'), content: [ this.data.activities_for_rent ] },
                    { title: this.t('Local traffic'), content: [ this.data.local_transport, this.data.local_transport_link ? { type: 'link', link: this.data.local_transport_link, text: this.t('Local transport website') } : null ] },
                    { title: this.t('Local historic'), content: [ this.data.local_historic ] },
                ]);
            } else if(this.data === null && this.map !== null) {
                this.destroyMap();
            }
        },
        close: function() {
            // if(this.$root.isPortNode) return; // cant close if in port node
            this.$root.hidePortDetails();
            document.removeEventListener('click', this.onClickEvent);
        },
        onClickEvent: function(event) {
            if(!this.$root.selectedPort) return;
            if (!event.target.closest('#app-container') || event.target.closest("#port-details-inner")) return;
            this.close();
        },
        flag: function(event) {
            this.flag_processing = true;
            event.preventDefault();

            Axios.get(Drupal.absoluteUrl(this.$root.selectedPortData.favorite.link.replace(':action', this.flag_action))).then(response => {
                this.flag_action = this.flag_action == 'flag' ? 'unflag' : 'flag';
                this.flag_processing = false;
            }).catch(err => {
                this.flag_processing = false;
                console.error(err);
            });
        },
        print: function() {
            this.setActiveTab('map');

            setTimeout(() => { window.print(); }, 1000);
        },
        setUpMap: function() {
            if(this.data.gps && !this.map) {
                this.map = L.map('port-map').setView(this.data.gps, 15);
                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);
                L.tileLayer('https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png').addTo(this.map);
                L.marker(this.data.gps).addTo(this.map);
            }
        },
        destroyMap: function() {
            if(this.map) {
                this.map.remove();
                this.map = null;
            }
        },
        gotoFirstImageWithAnimation: function() {
            if(this.currentImage <= 0) return;

            setTimeout(() => {
                this.currentImage--;
                this.gotoFirstImageWithAnimation();
            }, 100);
        },
        setActiveTab: function(page) {
            if(this.activeTab == page) return;
            
            this.activeTab = page;

            setTimeout(() => {
                if(page == 'map') {
                    this.setUpMap();
                } else {
                    this.destroyMap();
                }
            }, 100);
        },
        prevVideo: function() {
            this.currentVideo--;
        },
        nextVideo: function() {
            this.currentVideo++;
        },
        prevImage: function() {
            this.currentImage--;
        },
        nextImage: function() {
            this.currentImage++;
        },
        showAllServices: function() {
            this.data.port_services_2.forEach(i => this.services.push(i));
            this.showingAllServices = true;
        },
        toggleContent: function(e) {
            e.stopPropagation();
            e.currentTarget.parentElement.classList.toggle('opened');
        },
        // getFiveStarForm: function (index, nid, field) {
        //     Axios.post(Drupal.settings.basePath + 'fivestar_form/' + nid + '/field/' + field, 'js=1', { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).then((data) => {
        //         this.visits[index].form = data.data.form;
        //         this.visits[index].value = this.data['ratings_' + index];
        //         this.visits[index].nid = this.data.id;
        //     });
        // },
        rateFeature: function(e, index, visit, value) {
            e.preventDefault();
            if(!Drupal.settings.logged_in) return;
            visit.value = value;

            Axios.post(Drupal.settings.basePath + 'fivestar_form/' + this.data.id + '/rate/field_feature_' + (index + 1) +'_rating', 'js=1&value=' + value, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).then((data) => {
            });
            // element.find('a').click(function(e) {
            //     e.stopPropagation();
            //     e.preventDefault();

            //     var a = $(e.target);
            //     var val = a.attr('href').replace(/[^0-9]/g, '');

            //     $.ajax({
            //         type: 'POST',
            //         url: Drupal.settings.basePath + 'fivestar_form/' + nid + '/rate',
            //         dataType: 'json',
            //         success: function(data) { setFiveStar(data, nid, element_id); },
            //         data: 'js=1&value=' + val,
            //     });
            // });
        },
        setRatingData: function() {
            for(let idx in this.visits) {
                this.visits[idx].value = this.data['ratings_' + idx];
            }
        },
        t: function(text) {
            return Drupal.settings.portmate_translations[text];
        }
    }
};
</script>