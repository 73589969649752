<template>
    <div id="book-toc-footer" v-if="!is_parent">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1 col-xs-12">
                    <div class="footer-content">
                        <div class="nav-arrows">
                            <a v-if="prev" :class="{ disabled: !prev }" class="nav-arrow prev" @click="gotoPrev">{{ t('previous') }}<span class="book-title">{{ prev.title }}</span></a>
                            <a v-if="next" :class="{ disabled: !next }" class="nav-arrow next" @click="gotoNext">{{ t('next') }}<span class="book-title">{{ next.title }}</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// const BookTocItem = require('./BookTocItem.vue').default;

module.exports = {
    // components: { BookTocItem },
    data: function() {
        const book_toc = Drupal.settings.book_toc;

        return {
            is_parent: book_toc.is_parent,
            next: book_toc.next,
            prev: book_toc.prev,
        };
    },
    methods: {
        openTOCMenu: function() {
            this.$root.openMenu();
        },
        gotoNext: function() {
            if(this.next) window.location.href = this.next.link;
        },
        gotoPrev: function() {
            if(this.prev) window.location.href = this.prev.link;
        },
        t: function(text) {
            return Drupal.settings.portmate_translations[text];
        }
    },
};
</script>