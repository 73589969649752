window.Vue = require('vue');

var App = require('./vue-templates/App.vue').default;
var PortSearch = require('./vue-templates/PortSearch.vue').default;
var BookTocOnPage = require('./vue-templates/BookTocOnPage.vue').default;
var BookTocMenu = require('./vue-templates/BookTocMenu.vue').default;
var BookTocHeader = require('./vue-templates/BookTocHeader.vue').default;
var BookTocFooter = require('./vue-templates/BookTocFooter.vue').default;
var Weather = require('./vue-templates/Weather.vue').default;

document.addEventListener('DOMContentLoaded', function() { initVueComponents(); initEventListeners(); });

var navOpen = false;
var navigation = null;
var navToggler = null;

function initEventListeners() {
    navigation = document.getElementById('navigation');
    navToggler = document.getElementById('nav-toggler');
    
    navToggler.addEventListener('click', function() { toggleNav(); });

    document.addEventListener('click', function(e) {
        if(!navigation || !navOpen) return;
        if(!navigation.contains(e.target) && !navToggler.contains(e.target)) toggleNav();
    });

    document.getElementById('close-navigation').addEventListener('click', function(e) { toggleNav(); });

    const langToggler = document.getElementById('lang-toggler');
    if(langToggler) document.getElementById('lang-toggler-active').addEventListener('click', function(e) { langToggler.classList.toggle('open'); });
}

function toggleNav() {
    if(!navigation) return;

    navOpen = !navOpen;
    navigation.classList.toggle('open'); 
}

function initVueComponents() {
    if(document.getElementById('main-app')) {
        const vueapp = new Vue(App).$mount('#main-app');

        if(document.getElementById('port-search-container')) new Vue(PortSearch).$mount('#port-search-container').setMainApp(vueapp);
    } else {
        document.body.classList.add('has-not-app');
    }

    if(document.getElementById('weather-footer')) new Vue(Weather).$mount('#weather-footer');

    if(document.querySelector('body.node-type-book')) initBookVueComponent();
    // if(document.getElementById('book-toc-on-page')) new Vue(BookTocOnPage).$mount('#book-toc-on-page');
    // if(document.getElementById('book-toc-menu')) new Vue(BookTocMenu).$mount('#book-toc-menu');
}

function initBookVueComponent() {
    const vm = new Vue({
        el: '#content-main-container',
        components: {
            BookTocMenu,
            BookTocOnPage,
            BookTocHeader,
            BookTocFooter,
        },
        data: function() {
            const book_toc = Drupal.settings.book_toc;
            const trail = [];
            const activeId = book_toc.current;

            const recursiveFunc = function(depth, element) {
                if(element.id == activeId) {
                    trail[depth] = element.id;
                    return true;
                } else {
                    if(element.below.length > 0) {
                        for(var i in element.below) {
                            if(recursiveFunc(depth + 1, element.below[i])) {
                                trail[depth] = element.id;
                                return true;
                            }
                        }
                    }
                }

                return false;
            }

            for(var i in book_toc.hierarchy) recursiveFunc(0, book_toc.hierarchy[i]);

            var activePage = null;

            if(trail.length > 0) {
                activePage = trail.pop();
            }

            return {
                menu_open: false,
                activeTrail: trail,
                activePage: activePage,
            }
        },
        methods: {
            openMenu: function() {
                this.menu_open = true;
            },
            closeMenu: function() {
                this.menu_open = false;
            },
            t: function(text) {
                return Drupal.t(text);
            }
        }
    });
}