<template>
    <div id="book-toc-menu" :class="{ visible: visible }" v-if="toc">
        <div class="menu-title">
            <a :href="toc.link">{{ toc.title }}</a>
            <a @click="close" class="menu-close-button close-button material-icons">add_circle</a>
        </div>
        <div class="menu-items">
            <book-toc-item :item="itm" :key="itm.id" v-for="itm in toc.below"></book-toc-item>
        </div>
    </div>
</template>
<script>
const BookTocItem = require('./BookTocItem.vue').default;

module.exports = {
    props: [ 'visible' ],
    components: { BookTocItem },
    data: function() {
        const book_toc = Drupal.settings.book_toc;

        return {
            is_parent: book_toc && book_toc.is_parent,
            toc: book_toc ? book_toc.hierarchy[0] : null
        }
    },
    methods: {
        close: function() {
            this.$root.closeMenu();
        },
        onClickEvent: function(event) {
            if(!this.visible) return;
            if (event.target.closest("#book-toc-menu")) return;
            this.close();
        }
    },
    mounted: function() {
        setTimeout(() => {
            document.addEventListener("click", this.onClickEvent);
        }, 200);
    },
};
</script>