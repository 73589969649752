<template>
    <div class="port" v-on:click="showPortDetails" v-bind:class="{ active: active }">
        <div class="intro">
            <div class="title subtitle-1">{{ title }}</div>
            <h4 class="name">{{ port.name }}</h4>
            <p class="summary">{{ port.info ? port.info.value : '' }}</p>
        </div>
        <div class="image" v-bind:style="'background-image: url(\'' + port.image + '\');'"></div>
    </div>
</template>
<script>
module.exports = {
    props: [ 'active', 'port', 'title', ],
    methods: {
        showPortDetails: function() {
            this.$root.viewPortDetails(this.port);
        },
    },
};
</script>