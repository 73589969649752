<template>
    <div class="port-search-item" @click="showPort">
        <div class="grid-col image" :style="port.image ? 'background-image: url(\'' + port.image + '\');' : ''"></div>
        <div class="grid-col">
            <a v-on:click="showPort">
                {{ port.name }}
                <img class="country-flag" v-if="port.country_code" :src="country_flag_path + port.country_code + '.png'" />
            </a>
        </div>
        <div class="grid-col">
            <a v-on:click="showPort">&gt;</a>
        </div>
    </div>
</template>

<script>
module.exports = {
    props: [ 'port'],
    data: function() {
        return {
            boat: Drupal.settings.basePath + 'sites/all/themes/portmate_theme/css/images/boat.svg',
            country_flag_path: Drupal.settings.basePath + 'sites/all/themes/portmate_bs/images/countries/',
        }
    },
    methods: {
        showPort: function() {
            this.$emit('portselected');
        }
    }
}
</script>
