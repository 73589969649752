<template>
    <div id="weather" class="hide-from-print">
        <div class="v-center w100">
            <div class="fl place">{{ city }}</div>
            <div v-if="loadingWeather" class="fl weather-loading">
                {{ t('fetching_weather_data') }}
            </div>
            <div class="fl degrees" v-if="canShowWeather && degrees">
                <img :src="weatherIcon" :class="weatherIcon" />
                {{ degrees }}&deg;
            </div>
            <div class="fl wind" v-if="canShowWeather">
                <div class="wind-direction">
                    <img :src="wind_icon_path + windSpeedAsColor + '-' + windDirection + '.png'" />
                    <!-- <i class="wd-icon material-icons" :style="'transform: rotate(' + windDirection + 'deg);'">arrow_downward</i> -->
                </div>
                <div class="wind-text" v-if="windSpeed != ''">{{ windSpeed }} m/s</div>
            </div>
            <div v-if="seaForecastLink" class="fl">
                <a target="_blank" v-if="!playingForecast" class="sea-forecast link-with-icon" @click="forecastToggle">
                    <i class="material-icons">play_circle_outline</i><span class="link-text">{{ t('listen_sea_forecast') }}</span>
                </a>
                <audio id="seaForecastPlayerSource">
                    <source :src="seaForecastLink" type="audio/mp3">
                </audio>
                <!-- <a target="_blank" v-if="playingForecast" class="sea-forecast link-with-icon" @click="forecastToggle">
                    <i class="material-icons">pause_circle_outline</i>
                    <span id="sea-forecast-progress-wrapper"></span>
                    <span class="sea-forecast-progress"></span>
                </a> -->
            </div>
            <div class="logos">
                <img src="/sites/all/themes/portmate_bs/images/eu.png" />
                <img src="/sites/all/themes/portmate_bs/images/interreg.png" />
            </div>
        </div>
    </div>
</template>
<script>
const Axios = require('axios');
const convert = require('xml-js');
const Moment = require('moment');
window.Plyr = require('plyr');

module.exports = {
    data: function() {
        return {
            city: '',
            country: '',
            degrees: null,
            weatherIcon: null,
            windSpeed: null,
            windDirection: null,
            seaForecastLink: null,
            weather: null,
            canShowWeather: false,
            dayTime: {
                sunrise: null,
                sunset: null,
                is_night: null,
            },
            loadingWeather: true,
            playingForecast: false,
            forecastAudio: null,
            wind_icon_path: Drupal.settings.basePath + 'sites/all/themes/portmate_bs/images/wind/',
        };
    },
    mounted: function() {
        const setWeatherData = (data) => {
            const fc = data.time[0].location;

            this.degrees = parseFloat(fc.temperature._attributes.value);
            if(this.degrees >= 0) this.degrees = '+' + this.degrees;
            else this.degrees = -(Math.round(Math.abs(this.degrees)));

            this.windSpeed = Math.round(parseFloat(fc.windSpeed._attributes.mps));

            if(this.windSpeed <= 3) this.windSpeedAsColor = 'green';
            else if(this.windSpeed <= 7) this.windSpeedAsColor = 'yellow';
            else if(this.windSpeed <= 13) this.windSpeedAsColor = 'orange';
            else this.windSpeedAsColor = 'red';
            
            this.windDirection = fc.windDirection._attributes.name.toLowerCase();

            const sym = data.time[1].location;

            if(sym.symbol) {
                this.weatherIcon = 'https://api.met.no/weatherapi/weathericon/1.1/?symbol=' + sym.symbol._attributes.number + '&is_night=' + (this.dayTime.is_night ? 1 : 0) + '&content_type=image/png';
            }
        };

        const setSunData = (data) => {
            this.dayTime.sunrise = parseInt((new Moment(data.sunrise._attributes.time)).format('x'));
            this.dayTime.sunset = parseInt((new Moment(data.sunset._attributes.time)).format('x'));

            const ms = Date.now();

            this.dayTime.is_night = ms < this.dayTime.sunrise || ms < this.dayTime.sunset;
        }

        let ssd = window.localStorage.getItem('local_sunrise');
        let wthr = window.localStorage.getItem('local_forecast');

        if(ssd && wthr) {
            try {
                ssd = JSON.parse(ssd);
                wthr = JSON.parse(wthr);
            } catch(e) {
                ssd = null;
                wthr = null;
            }
        }

        if(wthr && ssd && wthr.expires > Date.now()) {
            setSunData(ssd.data);
            setWeatherData(wthr.data);
            this.loadingWeather = false;
            this.canShowWeather = true;
            navigator.geolocation.getCurrentPosition((location) => {
                this.setCity(location.coords);
            }, err => console.error(err));
        } else if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((location) => {
                // console.log(location);

                this.setCity(location.coords);

                const m = new Moment();
                Axios.get('https://api.met.no/weatherapi/sunrise/2.0/?lat=' + location.coords.latitude + '&lon=' + location.coords.longitude + '&date=' + m.format('YYYY-MM-DD') + '&offset=' + m.format('Z')).then(response => {
                    const data = convert.xml2js(response.data, { compact: true });
                    let info = data.astrodata.location.time;

                    if(info) {
                        if(info[0]) {
                            info = info[0];
                        }

                        setSunData(info);
                        window.localStorage.setItem('local_sunrise', JSON.stringify({ expires: Date.now() + 300000, data: info }));
                        return Axios.get('https://api.met.no/weatherapi/locationforecast/1.9/?lat=' + location.coords.latitude + '&lon=' + location.coords.longitude);
                    } else {
                        return Promise.reject('Sunset..');
                    }
                }).then((response) => {
                    const data = convert.xml2js(response.data, { compact: true });
                    const prod = data.weatherdata.product;

                    if(prod.time && prod.time.length > 2) {
                        setWeatherData(prod);
                        window.localStorage.setItem('local_forecast', JSON.stringify({ expires: Date.now() + 300000, data: prod }));
                        this.loadingWeather = false;
                        this.canShowWeather = true;
                    }
                }).catch((error) => {
                    console.error(error);
                });
            }, error => console.error(error));
        }
    },
    methods: {
        t: function(text) {
            return Drupal.settings.portmate_translations[text];
        },
        setCity: function(new_location) {
            const lastKnownLocation = window.localStorage.getItem('last_known_location');
            let getNewLocation = false;
            let lastJson = null;

            if(!lastKnownLocation) getNewLocation = true;
            else {
                lastJson = JSON.parse(lastKnownLocation);

                if(this.locationDiffers(lastJson.location, new_location)) getNewLocation = true;
                else {
                    this.city = lastJson.city;
                    this.country = lastJson.country;
                    this.setSeaForecastLink();
                }
            }

            if(getNewLocation) {
                const urlParams = [ 'prox=' + new_location.latitude + ',' + new_location.longitude ];
                urlParams.push('mode=retrieveAddresses');
                urlParams.push('gen=9');
                urlParams.push('app_id=VLf5fCamzbdkBwA3cXRC');
                urlParams.push('app_code=jK0vD2F-bO3hFx6m4IkOig');
                Axios.get('https://reverse.geocoder.api.here.com/6.2/reversegeocode.json?' + urlParams.join('&')).then(response => {
                    const Response = response.data.Response;
                    if(Response.View.length) {
                        const city = Response.View[0].Result[0].Location.Address.City;
                        const country = Response.View[0].Result[0].Location.Address.Country;
                        this.city = city;
                        this.country = country;
                        window.localStorage.setItem('last_known_location', JSON.stringify({ location: new_location, city: city, country: country }));
                        this.setSeaForecastLink();
                    }
                }).catch(err => console.error(err));
            }
        },
        setSeaForecastLink: function() {
            let lang = null;
            
            switch(Drupal.settings.portmate_language) {
                case 'fi':
                    lang = 'FIN';
                    break;
                case 'sv':
                    lang = 'SWE';
                    break;
            }

            if(Drupal.settings.sea_forecast_links && lang && typeof(Drupal.settings.sea_forecast_links[lang]) != 'undefined') {
                this.seaForecastLink = Drupal.settings.sea_forecast_links[lang];
                // this.forecastAudio = new Audio(this.seaForecastLink);
            }
        },
        locationDiffers(old_location, new_location) {
            let latDif = Math.abs(old_location.latitude - new_location.latitude);
            let lonDif = Math.abs(old_location.longitude - new_location.longitude);
            
            return latDif > 0.005 || lonDif > 0.005;
        },
        forecastToggle() {
            if(!this.forecastAudio) {
                this.forecastAudio = new Plyr(document.getElementById('seaForecastPlayerSource'), {
                    iconUrl: Drupal.settings.basePath + 'sites/all/themes/portmate_bs/images/plyr.svg',
                    controls: [ 'play', 'progress', 'current-time', 'mute', 'volume' ]
                });

                this.forecastAudio.play();
            }

            // if(this.forecastAudio && !this.playingForecast) {
            //     this.forecastAudio.play();
            //     this.forecastPlayerProgress = this.forecastAudio.currentTime / this.forecastAudio.duration;

            //     this.forecastPlayerProgressBarInterval = setInterval(() => {
            //         this.forecastPlayerProgress = this.forecastAudio.currentTime / this.forecastAudio.duration;
            //     }, 1000);
            // } else if(this.forecastAudio) {
            //     this.forecastAudio.pause();
            //     if(this.forecastPlayerProgress) clearInterval(this.forecastPlayerProgress);
            //     // if(element.readyState == 4) {
            //     //     element.pause();
            //     // } else {
            //     //     element.canplay = undefined;
            //     // }
                
            // }
            this.playingForecast = !this.playingForecast;
        },
        progressBarClick(e) {
            this.forecastAudio.currentTime = Math.floor(this.forecastAudio.duration * ((e.pageX - e.target.offsetLeft) / e.target.offsetWidth));
            this.forecastPlayerProgress = this.forecastAudio.currentTime / this.forecastAudio.duration;
        }
    }
}
</script>