<template>
    <div class="book-toc-item" :class="{ 'not-parent': item.depth > 2, 'active': activePage, 'parent': item.depth == 1, opened: opened }">
        <div class="book-toc-item-title" @click="toggle" :class="{ opened: opened }">
            <a class="link" :href="item.link">{{ item.title }}</a>
            <a class="pdf" v-if="item.pdf" :href="item.pdf" target="_blank">PDF</a>
            <a class="toggle" v-if="item.below.length"></a>
        </div>
        <div class="book-toc-item-children" v-if="opened && item.below.length">
            <book-toc-item v-for="subItem in item.below" :key="subItem.id" :item="subItem"></book-toc-item>
        </div>
    </div>
</template>
<script>
module.exports = {
    name: 'BookTocItem',
    props: [ 'item' ],
    data: function() {
        return {
            opened: this.$root.activeTrail.indexOf(this.item.id) >= 0,
            activePage: this.$root.activePage == this.item.id,
        }
    },
    mounted: function() {
        if(this.item.pdf) console.log(this.item);
    },
    methods: {
        toggle: function(e) {
            if(e.target.className.indexOf('link') === -1) {
                console.log(e);
                this.opened = !this.opened;
            }
        }
    },
};
</script>