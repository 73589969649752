<template>
    <div class="toggleable-content" v-bind:class="{ opened: opened }">
        <a class="title" @click="toggle">
            <span class="toggle-icon"></span>
            <span class="text" v-html="title"></span>
        </a>
        <div class="content">
            <div v-for="(part, index) in contents" :key="'part-' + index">
                <a v-if="typeof(part) === 'object' && part.type === 'link'" :href="part.link">{{ part.text }}</a>
                <div v-if="typeof(part) === 'string'" v-html="part"></div>
            </div>
        </div>
    </div>
</template>
<script>
module.exports = {
    props: [ 'title', 'content' ],
    mounted: function() {
        this.contents = this.$props.content.filter(i => i !== null);
    },
    data: function() {
        return {
            contents: [],
            opened: false,
        };
    },
    methods: {
        toggle: function() {
            this.opened = !this.opened;
        }
    },
};
</script>