<template>
    <div id="service-filter" v-bind:class="{ opened: opened }">
        <button class="mdc-button mdc-button--raised filter-button" @click="toggle">
            <i class="material-icons">arrow_drop_up</i>
            {{ t('nearby_places_and_services') }}
        </button>
        <div id="service-filter-list">
            <a class="close-button material-icons" @click="toggle">add_circle</a>
            <div id="service-filter-list-inner">
                <div class="mdc-form-field" v-for="(service, index) in services" :key="index">
                    <label :for="'sfl-checkbox-' + index">{{ service.title }}</label>
                    <div class="mdc-checkbox">
                        <input type="checkbox" class="mdc-checkbox__native-control" :id="'sfl-checkbox-' + index" v-model="services[index].checked" />
                        <div class="mdc-checkbox__background">
                        <svg class="mdc-checkbox__checkmark"
                            viewBox="0 0 24 24">
                            <path class="mdc-checkbox__checkmark-path"
                                fill="none"
                                d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                        </svg>
                        <div class="mdc-checkbox__mixedmark"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="service-filter-list-button">
                <button class="mdc-button mdc-button--raised" @click="search">
                    {{ t('search') }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
const Axios = require('axios');
const L = require('leaflet');

module.exports = {
    mounted: function() {
    },
    data: function() {
        return {
            contents: [],
            popups: [],
            opened: false,
            services: [
                { id: 'museum', title: this.t('museum'), checked: false },
                { id: 'restaurant', title: this.t('restaurant'), checked: false },
                { id: 'atm', title: this.t('atm'), checked: false },
                { id: 'liquor_store', title: this.t('liquor_store'), checked: false },
                { id: 'pharmacy', title: this.t('pharmacy'), checked: false },
                { id: 'post_office', title: this.t('post_office'), checked: false },
                { id: 'supermarket', title: this.t('supermarket'), checked: false },
                { id: 'hospital', title: this.t('hospital'), checked: false },
                { id: 'bus_station', title: this.t('bus_station'), checked: false },
            ]
        };
    },
    methods: {
        toggle: function() {
            this.opened = !this.opened;
        },
        t: function(txt) {
            return Drupal.settings.portmate_translations[txt];
        },
        search: function() {
            const location = this.$root.map.getCenter();
            this.clearMarkers();

            this.services.filter(i => i.checked).forEach(item => {
                const service = new google.maps.places.PlacesService(document.createElement('div'));

                const request = {
                    location: new google.maps.LatLng(location.lat, location.lng),
                    radius: '5000',
                    type: [ item.id ],
                };

                const callback = (results, status) => {
                    if (status == google.maps.places.PlacesServiceStatus.OK) {
                        for (var i = 0; i < results.length; i++) {
                            var place = results[i];
                            this.addMarker(place);
                        }
                    }
                }

                service.nearbySearch(request, callback);

                // Axios.get('https://maps.googleapis.com/maps/api/place/nearbysearch/json?key=&location=' + location.latitude + ',' + location.longitude + '&radius=5000&rankby=distance&type=' + item.id, { crossdomain: true }).then(data => { // & + types.join('&')
                //     console.log(data);
                // }).catch(err => console.error(err));
            });
        },
        clearMarkers: function() {
            this.popups.forEach(i => {
                this.$root.map.removeLayer(i);
            })
        },
        addMarker: function(data) {
            const info = [];

            info.push('<strong>' + data.name + '</strong>');
            info.push(data.vicinity);
            if(data.opening_hours) info.push(data.opening_hours.open_now ? '<span style="color: green;">Avoinna nyt</a>' : '<span class="color: red;">Suljettu tällä hetkellä</span>');
            if(data.user_ratings_total) info.push('Arvio: ' + data.rating.toString().replace('.', ',') + ' (' + data.user_ratings_total + ')');
            info.push('<em>' + this.services.filter(i => data.types.indexOf(i.id) >= 0).map(i => i.title) + '</em>');

            let icon = L.icon();

            if(data.icon) {
                icon = L.icon({
                    iconUrl: data.icon,
                    shadowUrl: null,
                    iconSize: [ 30, 30 ],
                    iconAnchor: [ 15, 15 ],
                    popupAnchor:  [ 0, -20 ],
                });
            }
            
            const marker = L.marker({
                lat: data.geometry.location.lat(),
                lon: data.geometry.location.lng(), 
            }, { title: data.name, icon: icon });

            marker.bindPopup(info.map(i => '<p>' + i + '</p>').join(''));
            marker.addTo(this.$root.map);

            this.popups.push(marker);
        }
    }
}
</script>