<template>
    <div id="app-container">
        <div v-if="isMounted">
            <featured></featured>
            <div class="shadow hide-from-print"></div>
            <sea-map></sea-map>
            <port-details v-if="selectedPort"></port-details>
            <!-- <weather></weather> -->
            <popup></popup>
            <service-filter></service-filter>
            <info-messages></info-messages>
        </div>
    </div>
</template>

<script>
// const Weather = require('./Weather.vue').default;
const Featured = require('./Featured.vue').default;
const SeaMap = require('./SeaMap.vue').default;
// const PortSearch = require('./PortSearch.vue').default;
const PortDetails = require('./PortDetails.vue').default;
const PortMapPopup = require('./PortMapPopup.vue').default;
const Popup = require('./Popup.vue').default;
const ServiceFilter = require('./ServiceFilter.vue').default;
const InfoMessages = require('./InfoMessages.vue').default;
// const Footer = require('./Footer.vue').default;
const Axios = require('axios');

module.exports = {
    components: {
        /* Weather, */ Featured, SeaMap, Popup, /*PortSearch, */ PortDetails, PortMapPopup, ServiceFilter, InfoMessages
    },
    methods: {
        search: function(event) {
            console.log('event');
        },
        t: function(text) {
            return Drupal.settings.portmate_translations[text];
        },
        getUserLocation: function() {
            return new Promise((resolve, reject) => {
                var func = () => {
                    if(!this.cantGetLocation) {
                        if(this.userLocation) {
                            if(interval) clearInterval(interval);
                            resolve(this.userLocation);
                        }
                    } else {
                        if(interval) clearInterval(interval);
                        reject('Couldn\'t get user\'s location');
                    }
                };

                var interval = null;
                func();

                interval = setInterval(func, 1000);
            });
        },
        getPorts: function() {
            return new Promise((resolve, reject) => {
                var func = () => {
                    if(!this.cantGetPorts) {
                        if(this.ports.length > 0) {
                            if(interval) clearInterval(interval);
                            resolve(this.ports.filter(port => {
                                if(this.filters.depth) {
                                    if(!port.depth || parseInt(this.filters.depth) > parseInt(port.depth)) {
                                        return false;
                                    }
                                }

                                if(this.filters.moorings.length) {
                                    if(!port.mooring.find(i => this.filters.moorings.indexOf(i) >= 0)) {
                                        return false;
                                    }
                                }

                                if(this.filters.services.length) {
                                    if(!port.services.find(i => this.filters.services.indexOf(i) >= 0)) return false;
                                }
                                
                                return true;
                            }));
                        }
                    } else {
                        if(interval) clearInterval(interval);
                        reject('Couldn\'t get ports');
                    }
                };

                var interval = null;
                func();

                interval = setInterval(func, 1000);
            });
        },
        viewPortOnMap: function(port) {
            if(port.location) this.map.setView(port.location, 13);
        },
        viewPortDetails: function(port) {
            this.$set(this, 'selectedPortData', null);
            if(this.selectedPortDataChanged) this.selectedPortDataChanged();

            if(port.path) {
                history.pushState({}, port.name, Drupal.absoluteUrl('/' + Drupal.settings.pathPrefix + port.path));
                this.pathSet = true;
                this.isPortNode = false;

                if(window.ga) window.ga('send', 'pageview', Drupal.settings.basePath + Drupal.settings.pathPrefix + port.path);
            }

            this.selectedPort = port.name || '';

            Axios.get(Drupal.absoluteUrl('/' + Drupal.settings.pathPrefix + 'api/v1.0/ports?filter[id]=' + port.id)).then(response => {
                if(response.data.count == 1) {
                    this.selectedPortData = response.data.data[0];
                    this.selectedPort = this.selectedPortData.port_name;
                    this.selectedPortExtraData = port.extra || null;
                    if(this.selectedPortDataChanged) this.selectedPortDataChanged(port.extra);
                    window.portTranslations = this.selectedPortData.translations.port;
                }
            }).catch(err => {
                console.error(err);
            });

            document.getElementById('app-container').classList.add('show-details');
        },
        hidePortDetails: function() {
            window.portTranslations = this.selectedPortData.translations.front;
            this.selectedPort = null;
            this.selectedPortData = null;

            history.pushState({}, 'Portmate', Drupal.absoluteUrl('/' + Drupal.settings.pathPrefix));

            document.getElementById('app-container').classList.remove('show-details');

        }
    },
    data: {
        ports: [],
        portsMarkers: [],
        map: null,
        promoted: [],
        nearby: [],
        userLocation: null,
        cantGetLocation: false,
        cantGetPorts: false,
        showMap: false,
        selectedPort: null,
        selectedPortData: null,
        selectedPortExtraData: null,
        pathSet: false,
        // isPortNode: false,
        selectedPortDataChanged: null,
        isMounted: false,
        filters: { depth: null, moorings: [], services: [] },
        updatePorts: null
    },
    mounted: function() {
        document.body.classList.add('has-app');
        this.$set(this, 'isMounted', true);

        if(Drupal.settings.portmate_port) {
            // const s = Drupal.settings.portmate_port;
            this.viewPortDetails(Drupal.settings.portmate_port);
            // this.isPortNode = true;
        }

        // axios.get(Drupal.absoluteUrl('api/v1.0/portsSearch?filter[label][value]=' + encodeURIComponent(this.term) + '&filter[label][operator]=CONTAINS')).then(function(response) {
        Axios.get(Drupal.absoluteUrl('/' + Drupal.settings.pathPrefix + 'api/v1.0/portsSearch?all=1')).then(response => {
            this.ports = response.data.data;
            window.ports = this.ports;
        }).catch(err => {
            this.cantGetPorts = true;
            consol.error(err);
        });

        var langToggler = document.getElementById('lang-toggler');

        langToggler.addEventListener('click', function(e) {
            if(window.portTranslations) {
                var target = e.target;
                var ptKeys = Object.keys(window.portTranslations)
                if(target.href) {
                    for(var i in ptKeys) {
                        if(ptKeys[i] == target.textContent) {
                            e.preventDefault();
                            window.location.href = window.portTranslations[ptKeys[i]];
                            return;
                        }
                    }
                }
            }
        });

        navigator.geolocation.getCurrentPosition(location => {
            this.userLocation = location.coords;
        }, error => {
            this.cantGetLocation = true;
            console.error(error);
        });
    },
}
</script>