<template>
    <div class="book-toc" v-if="is_parent">
        <!-- <book-toc-item v-for="item in toc" :key="item.id" :item="item"></book-toc-item> -->
        <book-toc-item :item="toc" :key="toc.id" v-for="toc in tocs"></book-toc-item>
    </div>
</template>
<script>
const BookTocItem = require('./BookTocItem.vue').default;

module.exports = {
    components: { BookTocItem },
    data: function() {
        const book_toc = Drupal.settings.book_toc;

        return {
            is_parent: book_toc.is_parent,
            tocs: book_toc && book_toc.hierarchy[0] && book_toc.hierarchy[0].below.length ? book_toc.hierarchy[0].below : [],
        }
    },
    methods: {
    },
};
</script>