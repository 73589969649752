<template>
  <div class="height-100">
    <div class="col-md-offset-2 col-md-8 height-100 port-search-outer">
      <div id="port-search" class="hide-from-print">
        <div id="port-search-input-container" class="mdc-text-field mdc-text-field--outlined mdc-text-field--with-leading-icon mdc-text-field--with-trailing-icon">
          <i class="material-icons mdc-text-field__icon">search</i>
          <input id="port-search-input" class="mdc-text-field__input" :placeholder="t('search_ports')" type="text" :value="term" @input="term = $event.target.value; search();" />
          <i class="material-icons mdc-text-field__icon clear-input" @click="clearSearchInput" v-if="term.length > 0">add_circle</i>
        </div>
        <div id="search-results">
            <port-search-item v-on:portselected="portSelected(item)" v-for="item in results" :key="item.id" v-bind:port="item"></port-search-item>
        </div>
        <button type="button" id="search-filter" class="mdc-icon-button material-icons" @click="toggleFilter">
          filter_list
        </button>
      </div>
    </div>
    <port-search-filter @onFilterChanged="filterChanged" @onFilterClose="toggleFilter"  v-if="filterVisible"></port-search-filter>
  </div>
</template>
<script>
const PortSearchItem = require('./PortSearchItem.vue').default;
const PortSearchFilter = require('./PortSearchFilter.vue').default;

module.exports = {
  components: {
      PortSearchItem,
      PortSearchFilter
  },
  data: function() {
    return {
      mainApp: null,
      results: [],
      filters: { depth: null, moorings: [], services: [] },
      term: "",
      lastTerm: "",
      filterVisible: false,
      clickEventListenerEvent: (e) => {
        if (!document.getElementById('port-search').contains(e.target)){
          this.closeSearch();
          window.removeEventListener('click', (e) => { this.clickEventListenerEvent(e); });
        }
      },
    };
  },
  mounted: function() {
    window.addEventListener('click', (e) => { this.clickEventListenerEvent(e); });
  },
  beforeDestroy: function() {
    window.removeEventListener('click', (e) => { this.clickEventListenerEvent(e); });
  },
  methods: {
    clearSearchInput: function() {
      this.term = '';
      this.closeSearch();
    },
    search: function(event) {
      var searchTerm = this.term.toLocaleLowerCase();
      if (searchTerm.replace(" ", "").length == 0) return;
      if (searchTerm == this.lastTerm) return;
      this.lastTerm = searchTerm;
      var founds = 0;

      console.log(searchTerm);

      this.results = this.mainApp.ports.filter((port) => {
        // if (founds >= 10) return false;

        if(this.filters.depth) {
          if(!port.depth || parseInt(this.filters.depth) > parseInt(port.depth)) {
            return false;
          }
        }

        if(this.filters.moorings.length) {
          if(!port.mooring.find(i => this.filters.moorings.indexOf(i) >= 0)) {
            return false;
          }
        }

        if(this.filters.services.length) {
          if(!port.services.find(i => this.filters.services.indexOf(i) >= 0)) return false;
        }

        if (port.name.toLocaleLowerCase().indexOf(searchTerm) >= 0) {
          founds++;
          return true;
        }

        return false;
      });

    },
    filterChanged: function(data) {
      this.filters = data;
      if(this.mainApp.updatePorts) this.mainApp.updatePorts();
      this.search();
    },
    toggleFilter: function() {
      this.filterVisible = !this.filterVisible;
    },
    closeSearch: function() {
      this.results.splice(0, this.results.length);
    },
    setMainApp(app) {
      this.mainApp = app;
    },
    t: function(text) {
      return Drupal.settings.portmate_translations[text];
    },
    portSelected: function(port) {
      this.closeSearch();
      this.mainApp.viewPortOnMap(port);
      this.mainApp.viewPortDetails(port);
    }
  }
};
</script>