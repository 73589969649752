<template>
    <div id="map-container">
        <div id="map" class="hide-from-print"></div>
        <div class="not-for-navigation">{{ t('map_navigation_disclaimer') }}</div>
    </div>
</template>

<script>
const PortMapPopup = require('./PortMapPopup.vue').default;
const Vue = require('vue');
const L = require('leaflet');

module.exports = {
    mounted: function() {
        this.$root.map = L.map('map').setView([ 62.1017504, 19.5339248 ], 6);
        this.$root.updatePorts = this.updatePorts;

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.$root.map);
        L.tileLayer('https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png').addTo(this.$root.map);

        this.$root.getUserLocation().then(coords => {
            this.$root.map.setView([ coords.latitude, coords.longitude ], 13);
        }).catch(err => { console.error(err); });
        
        this.updatePorts();
    },
    methods: {
        openPort: function(port) {
            this.$root.viewPortDetails(port);
        },
        updatePorts: function() {
            this.$root.getPorts().then(ports => {
                ports.forEach(port => {
                    if(!port.location) return true;

                    this.$root.portsMarkers.push({ 
                        id: port.id, 
                        marker: L.marker(port.location, { title: port.name,  })
                            // .bindPopup('<div id="active-popup-container"></div>')
                            .on('click', () => {
                                // this.$emit('portOpened', port);
                                this.openPort(port);

                                // const constructor = Vue.extend(PortMapPopup);
                                // const vm = new constructor({
                                //     propsData: {
                                //         map: this,
                                //         port: port
                                //     }
                                // }).$mount('#active-popup-container');
                                
                                // this.$root.selectedPort = port;
                            })
                            .addTo(this.$root.map)
                    });
                });
            }).catch(err => { console.error(err); });
        },
        t: function(text) {
            return Drupal.settings.portmate_translations[text];
        }
    }
};
</script>